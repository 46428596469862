<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Hapus Angsuran Kredit</strong>
        <a-button class="btn btn-outline-danger ml-4" @click="handleSubmit"
          >Hapus Angsuran Terpilih</a-button
        >
      </template>
      <div class="row pl-4 pr-4 pt-2 pb-4">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Tanggal</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-date-picker
                style="width: 130px;"
                v-model="maskInput.tgl"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">No Rekening</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                ref="rekeningfirst"
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="No Rekening"
                v-on:keydown.enter.native="checkRekening"
              />
              <!-- <a-input
                placeholder=""
                v-model="maskInput.rekcabang"
                style="width: 60px;"
                :readOnly="false"
                @input="rekeninglimit('rekcabang', 2, 'rerekening')"
              ></a-input>
              <a-input
                class="ml-2"
                placeholder=""
                v-model="maskInput.rerekening"
                style="width: 60px;"
                :readOnly="false"
                ref="rerekening"
                @input="rekeninglimit('rerekening', 2, 'rerekening1')"
              ></a-input>
              <a-input
                ref="rerekening1"
                class="ml-2"
                placeholder=""
                v-model="maskInput.rerekening1"
                style="width: 100px;"
                :readOnly="false"
                @keydown.enter="rekeningcheck"
              ></a-input> -->
              <a-button class="ml-2" @click="checkRekening">
                <a-icon type="search"></a-icon>
              </a-button>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Nama</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="maskInput.nama" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Alamat</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="maskInput.alamat" :readOnly="true"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Saldo Pokok</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style="width: 200px;"
                v-model="maskInput.saldo"
                :readOnly="true"
              ></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <a-table
            :columns="columnDefs"
            :dataSource="datatable"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <span slot="formatCurrency" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
            <label slot="tgl" slot-scope="text">
              {{ formatDate(text) }}
            </label>
            <span slot="selected" slot-scope="text, record, index">
              <a-checkbox
                :checked="record.selected"
                @change="(e) => checkBoxChange(e, record, index)"
              >
              </a-checkbox>
              <!-- <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a> -->
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'

import { Modal } from 'ant-design-vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  data() {
    return {
      norekening: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      maskInput: {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        tgl: moment(),
        rekening: '',
        nama: '',
        alamat: '',
        saldo: '0',
      },
      columnDefs: [
        {
          title: '#',
          scopedSlots: { customRender: 'selected' },
          width: 10,
          fixed: 'left',
        },
        {
          title: 'Tanggal',
          width: 100,
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'tgl' },
          // width: 100,
        },
        {
          title: 'Faktur',
          dataIndex: 'faktur',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Pokok',
          width: 70,
          dataIndex: 'pokok',
          scopedSlots: { customRender: 'formatCurrency' },
          // width: 100,
        },
        {
          title: 'Bunga',
          width: 70,
          dataIndex: 'bunga',
          scopedSlots: { customRender: 'formatCurrency' },
          // width: 100,
        },
        {
          title: 'Total',
          width: 70,
          dataIndex: 'total',
          scopedSlots: { customRender: 'formatCurrency' },
          // width: 100,
        },
      ],
      datatable: [],
    }
  },
  created() {},
  methods: {
    moment,
    rekeninglimit(from, limit, toref) {
      if (this.maskInput[from].length >= limit) {
        this.$refs[toref].$el.focus()
      }
    },
    checkBoxChange(e, record, index) {
      record.selected = e.target.checked
      // console.log('record', record.selected)
    },
    async checkRekening() {
      // console.log('this.norekening.length', this.norekening.length)
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'hapus_angsurankredit/cari?Rekening=' + this.norekening.toString(),
        )
        if (res) {
          // console.log('res.data', res.data)
          if (res.data !== null) {
            this.dataselectedrekening = this.$g.clone(res.data)
            var dataTarget = res.data
            var data = {
              selectedName: { key: 0, label: dataTarget.nama },
              selectedRekening: { key: 0, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              tabungan_id: dataTarget.id,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
            this.maskInput.nama = res.data.nama
            this.maskInput.alamat = res.data.alamat
            this.maskInput.saldo = res.data.saldo_pokok
            if (res.data.buku_angsurans.length !== 0) {
              this.datatable = []
              res.data.buku_angsurans.forEach((element) => {
                var nobj = {
                  selected: false,
                  ...element,
                }
                this.datatable.push(nobj)
              })
            }
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'hapus_angsurankredit/cari?Rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          this.maskInput.nama = res.data.nama
          this.maskInput.alamat = res.data.alamat
          this.maskInput.saldo = res.data.saldo_pokok
          if (res.data.buku_angsurans.length !== 0) {
            this.datatable = []
            res.data.buku_angsurans.forEach((element) => {
              var nobj = {
                selected: false,
                ...element,
              }
              this.datatable.push(nobj)
            })
          }
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async handleSubmit() {
      Modal.confirm({
        title: 'Apakah anda yakin?',
        content: 'Data tidak dapat dikembalikan!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var fd = []
          this.datatable.forEach((element) => {
            if (element.selected) {
              fd.push({ id: element.id })
            }
          })
          if (fd.length !== 0) {
            var res = await lou.customUrlPost2('hapus_angsurankredit', fd)
            if (res) {
              this.resetForm()
              // this.rekeningcheck()
            }
          } else {
            lou.shownotif('Tidak ada data', 'Tidak ada data yang dipilih!')
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    resetForm() {
      this.norekening = ''
      this.maskInput = {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        tgl: moment(),
        rekening: '',
        nama: '',
        alamat: '',
        saldo: '0',
      }
      this.datatable = []
      this.$refs.rekeningfirst.$el.focus()
    },
    formatCurrency(v) {
      return v === '' || v === null ? 0 : lou.curency(v)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatBoolean(v) {
      if (v) {
        return 'Ya'
      } else {
        return 'Tidak'
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
